import {
    Accordion,
    Carousel,
    Container,
} from "@peracto/peracto-blocks-tailwind";

export default {
    plugins: {
        content: [Carousel, Accordion],
        layout: [Container],
    },
};