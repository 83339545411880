import { theme } from "@chakra-ui/core";
import { css } from "@emotion/core";

export const brandColors = {
    green: '#7FBA23',
    greenHover: '#96C74A',
    greenActive: '#6FA31D',
    red: '#C22034',
    yellow: '#FFD522',
    paleGreen: '#F2F8E9',
    paleRed: '#FDF1F2',
    lightGrey: '#F2F2F2',
    grey: '#D8D8D8',
    darkGrey: '#444444',
    darkGreyHover: '#666666',
    darkGreyActive: '#222222',
    black: '#222222',
    white: '#FFFFFF',
};

// [container, breakpoint]
const sizes = {
    sm: ["520px", "576px"],
    md: ["720px", "768px"],
    lg: ["960px", "992px"],
    xl: ['1210px', '1250px'],
};

const breakpoints = Object.values(sizes).reduce((bp, size) => {
    bp.push(size[1]);
    return bp;
}, []);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export const EditorTheme = {
    ...theme,
    breakpoints,
    colors: {
        ...theme.colors,
        brand: brandColors,
    },
    fonts: {
        ...theme.fonts,
        heading: "Muli, sans-serif",
        body: "Muli, sans-serif",
    },
};

export const AdminTheme = {
    breakpoints: {
        mobileS: 320,
        mobileM: 375,
        mobileL: 425,
        tablet: 768,
        laptop: 992,
        desktop: 1024,
        desktopL: 1700,
    },
};

// Global styles designs: link to the client's style guide if there is one.

const bodyStyles = css`
    body {
        color: ${brandColors.darkGrey};
    }
`

const listStyles = css`
    ul {
        list-style: none;
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 20px;
        position: relative;
        li {
            line-height: 30px;
            padding-left: 20px;
            position: relative;
            &:before {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                top: 10px;
                height: 10px;
                width: 10px;
                margin-right: 10px;
                background-color: ${brandColors.darkGrey};
            }
        }
    }

    ol {
        list-style-type: decimal;
        list-style-position: inside;
    }
`

const tableStyles = css`
    table {
        border: none !important;
        width: 100%;

        tr {
            font-size: 1rem;
            color: ${brandColors.black};

            &:nth-of-type(odd) {
                background-color: ${brandColors.lightGrey};
            }

            &:first-of-type {
                background-color: ${brandColors.green};
                color: ${brandColors.white};
                font-size: 1rem;
                font-weight: bold;
            }
        }

        td {
            border: none !important;
            padding: 12px !important;
        }
    }
`

const inputs = css`
    label {
        color: ${brandColors.darkGrey} !important;
    }

    input,
    select,
    textarea {
        font-size: 14px;
        border: 1px solid;
        border-color: ${brandColors.grey} !important;
        border-radius: 3px;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-ms-input-placeholder,
        &:-moz-placeholder,
        &::placeholder {
            color: ${brandColors.darkGrey} !important;
        }

        &:hover:not([id^='react-select']) {
            border-color: ${brandColors.grey} !important;
        }

        &:focus:not([id^='react-select']) {
            border-color: ${brandColors.green} !important;
            background-color: ${brandColors.paleGreen} !important;
        }
    }

    select {
        + div > svg {
            color: ${brandColors.green} !important;
        }
    }

    .react-dropdown-select-content {
        input,
        select,
        textarea {
            &:focus {
                border-color: none !important;
                box-shadow: none !important;
            }
        }
    }

    input[type='radio'] {
        + div {
            transition: border-color 0.2s;
            border-color: ${brandColors.grey} !important;
            border-width: 1px !important;
        }

        &:hover {
            + div {
                border-color: ${brandColors.darkGrey} !important;
            }
        }
        &:checked {
            + div {
                background-color: ${brandColors.darkGrey} !important;
                border-color: ${brandColors.darkGrey} !important;
                box-shadow: inset 0px 0px 0px 2px ${brandColors.white} !important;

                span {
                    display: none;
                }
            }
        }
    }
`

const headings = css`
    h1 {
        font-size: 30px;
        line-height: 34px;
        margin-bottom: 10px;
        font-weight: 900;
    }

    h2 {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 15px;
        font-weight: 900;
    }

    h3 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0;
        font-weight: 900;
    }

    h4 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;
        font-weight: 900;
    }

    h5 {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 0;
        font-weight: 900;
    }

    h6 {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
        font-weight: 900;
    }

    p {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 0;
    }
`;

export const GlobalStyles = css`
    ${bodyStyles}
    ${listStyles}
    ${tableStyles}
    ${headings}
    ${inputs}
`;
