const { darken, lighten } = require('polished')

// Used so hover colors get automatically updated
const brandPrimaryColor = '#7FBA23'
const brandSecondaryColor = '#444444'
const brandBlackColor = '#222222'
const brandDangerColor = '#C22034'

const colors = {
    // Base colors
    'brand-primary': brandPrimaryColor,
    'brand-secondary': brandSecondaryColor,
    'brand-danger': brandDangerColor,
    'brand-pale-danger': '#FDF1F2',
    'brand-pale-primary': '#F2F8E9',
    'brand-white': '#FFFFFF',
    'brand-light-grey': '#F2F2F2',
    'brand-grey': '#D8D8D8',
    'brand-dark-grey': brandSecondaryColor,
    'brand-black': brandBlackColor,

    // Hover colors
    'brand-primary-lighten': lighten(0.05, brandPrimaryColor),
    'brand-primary-darken': darken(0.05, brandPrimaryColor),

    'brand-secondary-lighten': lighten(0.05, brandSecondaryColor),
    'brand-secondary-darken': darken(0.05, brandSecondaryColor),

    'brand-black-lighten': lighten(0.05, brandBlackColor),
    'brand-black-darken': darken(0.05, brandBlackColor),

    'brand-danger-lighten': lighten(0.05, brandDangerColor),
    'brand-danger-darken': darken(0.05, brandDangerColor),
}

const fonts = {
    heading: ['Muli, sans-serif'],
    body: ['Muli, sans-serif'],
}

module.exports = { colors, fonts }