const sharedClasses =
    'flex items-center justify-center text-sm md:text-base px-[1em] leading-[45px] h-[45px] rounded w-full sm:max-w-[270px] font-black transition-color duration-200 disabled:cursor-not-allowed disabled:opacity-30 hover:no-underline'

export const primary = {
    solidClasses: `${sharedClasses} bg-brand-primary text-brand-white hover:bg-brand-primary-lighten active:bg-brand-primary-darken hover:text-brand-white`,
    outlineClasses: `${sharedClasses} bg-none text-brand-secondary border-2 border-brand-primary hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary-darken`,
    linkClasses: `${sharedClasses} bg-none text-brand-primary`,
}

export const secondary = {
    solidClasses: `${sharedClasses} bg-brand-primary text-brand-white hover:bg-brand-primary-lighten active:bg-brand-primary-darken hover:text-brand-white`,
    outlineClasses: `${sharedClasses} bg-none text-brand-secondary border-2 border-brand-primary hover:bg-brand-primary hover:text-brand-white active:bg-brand-primary-darken`,
    linkClasses: `${sharedClasses} bg-none text-brand-primary`,
}

export const tertiary = {
    solidClasses: `${sharedClasses} bg-brand-secondary text-brand-white hover:bg-brand-secondary-lighten active:bg-brand-secondary-darken hover:text-brand-white`,
    outlineClasses: `${sharedClasses} bg-none text-brand-secondary border-2 border-brand-secondary hover:bg-brand-secondary hover:text-brand-white active:bg-brand-secondary-darken`,
    linkClasses: `${sharedClasses} bg-none text-brand-secondary`,
}

export const danger = {
    solidClasses: `${sharedClasses} bg-brand-danger text-brand-white hover:bg-brand-danger-lighten active:bg-brand-danger-darken hover:text-brand-white`,
    outlineClasses: `${sharedClasses} bg-none text-brand-secondary border-2 border-brand-danger hover:bg-brand-danger hover:text-brand-white active:bg-brand-danger-darken`,
    linkClasses: `${sharedClasses} bg-none text-brand-danger`,
}